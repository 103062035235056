.permits-index-page .applications.row {
    margin-bottom: 50px;
}

label {
    font-weight: normal;
}

.permits-create-page #contacts a.btn {
    margin-bottom: 10px;
}
